@import url("https://fonts.googleapis.com/css2?family=Oswald:wght@400;500&family=Patua+One&family=Tinos&display=swap");

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.font-patua {
  font-family: "Patua One", cursive;
}

.font-oswald {
  font-family: "Oswald", sans-serif;
}

.font-oswald1 {
  font-family: "Oswald", sans-serif;
  font-weight: 500;
}
.font-tinos {
  font-family: "Tinos", serif;
}

.mainContainer {
  width: 90vw;
  margin: 0 auto;
  max-width: 1200px;
  padding-bottom: 2rem;
}

.text {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* number of lines to show */
  line-clamp: 3;
  -webkit-box-orient: vertical;
}

.menuText {
  font-size: 18px;
  color: white;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.click {
  background: white;
  font-size: 10px;
  margin: 10px auto;
  padding: 10px;
  cursor: pointer;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
